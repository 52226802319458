import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 28;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>前回の収録失敗して編集が大変だった話</li>
        <li>zoomの便利な機能</li>
        <li>設定の表記を英語にしていると分からない</li>
        <li>USキーボードへの憧れ</li>
        <li>ブラインドタッチを覚えた時期</li>
        <li>北斗の拳タイピングゲーム</li>
        <li>初期世代のApple</li>
        <li>インターネットに触れ始めた時代</li>
        <li>ブラインドタッチを覚えたい</li>
        <li>スマホのフリック入力</li>
        <li>キーボードの変換ストレス</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
